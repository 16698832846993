"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var useGetProductCardWidth = function useGetProductCardWidth() {
  var ref = (0, _react.useRef)(null);

  var _useState = (0, _react.useState)(0),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      width = _useState2[0],
      setWidth = _useState2[1];

  (0, _react.useEffect)(function () {
    if (!ref.current) return undefined;

    var getWidth = function getWidth() {
      var _ref$current$children;

      var card = (_ref$current$children = ref.current.children) === null || _ref$current$children === void 0 ? void 0 : _ref$current$children[0];
      setWidth((card === null || card === void 0 ? void 0 : card.offsetWidth) || 0);
    };

    getWidth();
    window.addEventListener('resize', getWidth);
    return function () {
      window.removeEventListener('resize', getWidth);
    };
  }, [ref]);
  return [ref, {
    width: width
  }];
};

var _default = useGetProductCardWidth;
exports["default"] = _default;